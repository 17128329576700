import React, { useState } from 'react';
import { FaLinkedin, FaGithub, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import profilePic from '../Assets/profile.jpg'; // Your profile picture
import wallpaper from '../Assets/wallpaper.jpg'; // Your profile picture
import logo from '../Assets/logo.png'; // Your personal logo
import CV from '../Assets/Bcced.pdf'; // Your CV
import { FaCode, FaTools, FaLaptopCode } from 'react-icons/fa'; 
import { FaAward, FaUniversity, FaCertificate ,FaLock } from 'react-icons/fa';

const LandingPage = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };


  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <div style={{ fontFamily: 'roboto' }}>
      {/* Header */}
      <header className="fixed top-0 w-full  text-white py-4 shadow-md z-50">
        <motion.div
          className="container mx-auto flex justify-between items-center px-4 md:px-8"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ type: 'spring', stiffness: 120 }}
        >
          <img src={logo} alt="Cedric's Logo" className="h-10 rounded-full" />
          <nav className="relative">
            {/* Floating Action Button for small screens */}
            <div className="md:hidden fixed top-4 right-4 z-20">
              <button onClick={toggleNav} className="bg-red-600 text-white p-2 rounded-full focus:outline-none">
                {navOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
              </button>
            </div>

            {/* Navigation Links */}
            <ul
              className={`${
                navOpen ? 'flex' : 'hidden'
              } flex-col space-y-4 fixed top-0 right-0 h-full w-3/4  text-white font-bold bg-gray-500 z-10 p-6 md:flex md:space-y-0 md:flex-row md:space-x-8 md:bg-transparent md:w-auto md:static md:h-auto md:p-0 md:relative md:text-white`}
            >
              {['#home', '#about', '#skills', '#experience', '#projects', '#contact'].map((hash, idx) => (
                <li key={idx}>
                  <a href={hash} className="transition duration-300 hover:text-red-500">
                    {hash.substring(1).charAt(0).toUpperCase() + hash.substring(2)}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </motion.div>
      </header>

      {/* Home Section */}
      <section
        id="home"
        className="relative h-screen flex items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: `url(${wallpaper})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <motion.div
          className="relative z-10 text-center text-white px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-5xl font-extrabold mb-6 animate-pulse">Cedric Byiringiro</h1>
          <p className="text-lg">Software Developer | Full Stack | ReactJS | ReactNative | NodeJS</p>
          <motion.button
            className="mt-6 px-6 py-2 bg-red-600 text-white font-semibold rounded hover:bg-red-500 transition duration-300"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <a href={CV} download>Download CV</a>
          </motion.button>
        </motion.div>
      </section>

      {/* About Section */}
      <section id="about" className="py-16 bg-gradient-to-r from-blue-50 to-gray-100">
  <div className="container mx-auto text-center">
    <h2 className="text-5xl font-bold mb-12 text-red-900">About Me</h2>

    <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-12">
      {/* Profile Image */}
      <motion.div
        className="relative group"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.2 }}
      >
        <img
          src={profilePic} 
          alt="Profile"
          className="w-64 h-64 rounded-full shadow-lg transition duration-300 transform group-hover:scale-105 group-hover:rotate-6"
        />
        <div className="absolute inset-0 bg-black opacity-30 rounded-full group-hover:opacity-0 transition duration-300"></div>
      </motion.div>
          {/* About Text */}
          <motion.div
            className="text-center md:text-left max-w-lg"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2 }}
          >
            <p className="text-lg text-gray-700 leading-relaxed mb-6">
              I'm Cedric Byiringiro, a dedicated software engineer with expertise in modern technologies such as ReactJS, NodeJS, and MongoDB. 
              {isReadMore && (
                <>
                  Passionate about creating efficient and user-friendly applications, I strive to innovate and drive excellence in every project I work on.
                  With experience in front-end and back-end development, I enjoy exploring new technologies and solving complex challenges.
                </>
              )}
              {!isReadMore && '...'}
            </p>
            <button
              onClick={toggleReadMore}
              className="text-blue-600 hover:text-blue-800 transition duration-300"
            >
              {isReadMore ? 'Read Less' : 'Read More'}
            </button>
          </motion.div>
    </div>

    {/* Certification & Education Cards */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
      {/* Card 1 - Certifications */}
      <motion.div
        className="bg-white shadow-lg p-6 rounded-lg text-center hover:shadow-xl transition duration-300"
        whileHover={{ scale: 1.05 }}
      >
        <FaCertificate className="text-5xl text-blue-500 mb-4" />
        <h3 className="text-2xl font-semibold text-gray-800">Certifications</h3>
        <p className="text-gray-600 mt-4">Cisco Cybersecurity, Linux Essentials, Networking Essentials</p>
      </motion.div>

      {/* Card 2 - Education */}
      <motion.div
        className="bg-white shadow-lg p-6 rounded-lg text-center hover:shadow-xl transition duration-300"
        whileHover={{ scale: 1.05 }}
      >
        <FaUniversity className="text-5xl text-green-500 mb-4" />
        <h3 className="text-2xl font-semibold text-gray-800">Education</h3>
        <p className="text-gray-600 mt-4">Bachelor’s Degree in Software Engineering - AUCA</p>
      </motion.div>

      {/* Card 3 - Achievements */}
      <motion.div
        className="bg-white shadow-lg p-6 rounded-lg text-center hover:shadow-xl transition duration-300"
        whileHover={{ scale: 1.05 }}
      >
        <FaAward className="text-5xl text-yellow-500 mb-4" />
        <h3 className="text-2xl font-semibold text-gray-800">Achievements</h3>
        <p className="text-gray-600 mt-4">Delivered projects for clients in Rwanda and globally, including DigitalHQ</p>
      </motion.div>
    </div>
  </div>
</section>

      {/* Experience Section */}
      <section id="experience" className="py-16 bg-gradient-to-r from-gray-50 to-gray-200">
  <div className="container mx-auto text-center">
    <h2 className="text-4xl font-bold mb-12 text-red-900">Experience</h2>
    <div className="space-y-12">
      <motion.div
        className="flex flex-col md:flex-row items-center bg-white p-6 rounded-lg shadow-lg"
        whileHover={{ scale: 1.05 }}
      >
        <div className="flex-shrink-0 bg-red-500 text-white p-4 rounded-full">
          <FaLaptopCode size={40} />
        </div>
        <div className="md:ml-6 text-left">
          <h3 className="text-2xl font-bold text-gray-900">Front-end Developer - Solvit Africa</h3>
          <p className="text-gray-600 mt-2">March 2021 - July 2022</p>
          <p className="mt-4 text-gray-700">
            Developed responsive web applications using ReactJS and enhanced user experience through
            intuitive design and clean coding practices.
          </p>
        </div>
      </motion.div>

      <motion.div
        className="flex flex-col md:flex-row items-center bg-white p-6 rounded-lg shadow-lg"
        whileHover={{ scale: 1.05 }}
      >
        <div className="flex-shrink-0 bg-blue-500 text-white p-4 rounded-full">
          <FaTools size={40} />
        </div>
        <div className="md:ml-6 text-left">
          <h3 className="text-2xl font-bold text-gray-900">IT Support Technician - MTN Rwanda</h3>
          <p className="text-gray-600 mt-2">March 2018 - September 2019</p>
          <p className="mt-4 text-gray-700">
            Troubleshot and resolved technical issues, managed system configurations, and provided
            timely support to over 200 users within the organization.
          </p>
        </div>
      </motion.div>

      <motion.div
        className="flex flex-col md:flex-row items-center bg-white p-6 rounded-lg shadow-lg"
        whileHover={{ scale: 1.05 }}
      >
        <div className="flex-shrink-0 bg-green-500 text-white p-4 rounded-full">
          <FaCode size={40} />
        </div>
        <div className="md:ml-6 text-left">
          <h3 className="text-2xl font-bold text-gray-900">Front-end Developer - DigitalHQ</h3>
          <p className="text-gray-600 mt-2">March 2023 - January 2024</p>
          <p className="mt-4 text-gray-700">
            Worked remotely, developing sleek and interactive web applications using React.js and
            Next.js, focusing on performance and scalability.
          </p>
        </div>
      </motion.div>
    </div>
  </div>
</section>
     {/* Projects Section */}
<section id="projects" className="py-16 bg-white">
  <div className="container mx-auto text-center">
    <h2 className="text-4xl font-bold mb-8 text-red-900">Projects</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {[
        {
          title: 'VSP Dealers',
          description: 'A website for managing and showcasing various product dealerships. Built with a focus on user experience and streamlined design.',
          link: 'https://vspdealers.pages.dev'
        },
        {
          title: 'EPD Rwanda',
          description: 'An informative platform for EPD Rwanda, providing insights and resources for environmental protection and development initiatives.',
          link: 'https://epd-rwanda.netlify.app'
        },
        {
          title: 'Ituze Garden Palace',
          description: 'The official website for Ituze Garden Palace, offering information on accommodation, services, and bookings in Rwanda.',
          link: 'https://ituzegardenpalace.rw'
        }
      ].map((project, idx) => (
        <motion.div
          key={idx}
          className="bg-gray-100 p-6 rounded-lg shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-2xl font-semibold text-gray-800">{project.title}</h3>
          <p className="mt-4 text-gray-700">{project.description}</p>
          <a
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-600 hover:text-red-800 mt-4 inline-block transition duration-300"
          >
            Visit Site
          </a>
        </motion.div>
      ))}
    </div>
    {/* Private Project Section */}
    <div className="bg-white shadow-lg p-6 rounded-lg text-center relative group mt-10">
      <FaLock className="text-gray-500 text-3xl mb-4" />
      <h3 className="text-2xl font-semibold text-gray-800">Private Information</h3>
      <p className="text-gray-600 mt-4">Several projects are not displayed due to privacy restrictions. Contact us for more information.</p>
      <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition duration-300 flex items-center justify-center text-white">
        <p>Details available upon request</p>
      </div>
    </div>
  </div>
</section>


      {/* Contact Section */}
      <section id="contact" className="py-16 bg-gray-50"> <div className="container mx-auto text-center"> <h2 className="text-4xl font-bold mb-8 text-red-900">Contact Me</h2> <div className="flex justify-center space-x-6"> <a href="https://www.linkedin.com/in/cedricbyiringiro" className="text-blue-600" target="_blank" rel="noreferrer"> <FaLinkedin size={32} /> </a> <a href="https://github.com/cedrulion" className="text-gray-800" target="_blank" rel="noreferrer"> <FaGithub size={32} /> </a> <a href="mailto:byiringirocedric012@gmail.com" className="text-red-600"> <FaEnvelope size={32} /> </a> </div> <p className="text-center mt-6 text-gray-600">Kigali, Rwanda | +250784960378</p> </div> </section>
  {/* Footer */}
  <footer className="bg-gray-800 text-gray-400 py-6 text-center">
    <p>&copy; 2024 Cedric Byiringiro. All rights reserved.</p>
  </footer>
</div>
); };

export default LandingPage;


